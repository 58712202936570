import { CheckCircle, XCircle } from 'lucide-react';

const myths = [
  {
    myth: 'Vasectomy affects sexual performance and drive',
    reality: 'Vasectomy only blocks sperm transport. It does not affect testosterone levels, sex drive, erections, or sexual pleasure. The procedure has no impact on sexual performance.',
  },
  {
    myth: 'Vasectomy is a complicated surgery',
    reality: 'Vasectomy is a simple, minimally invasive procedure that typically takes 20-30 minutes under local anesthesia. Most men return to normal activities within a few days.',
  },
  {
    myth: 'Vasectomy increases cancer risk',
    reality: 'Multiple large-scale studies have shown no link between vasectomy and any type of cancer, including prostate cancer.',
  },
  {
    myth: 'Vasectomy causes weight gain and hormonal changes',
    reality: 'Vasectomy does not affect hormone production or metabolism. Any weight changes after vasectomy are not related to the procedure.',
  },
  {
    myth: 'Reversal is always possible',
    reality: 'While reversal is possible, success rates vary and decrease with time. Vasectomy should be considered permanent. Reversal procedures are complex and expensive.',
  },
  {
    myth: 'Vasectomy is immediately effective',
    reality: 'It takes about 20-30 ejaculations or 3 months to clear existing sperm. Additional contraception is needed until a semen analysis confirms zero sperm count.',
  },
  {
    myth: 'The procedure is extremely painful',
    reality: 'Most men experience only mild discomfort during and after the procedure. Local anesthesia prevents pain during the surgery, and recovery pain is typically minimal.',
  },
  {
    myth: 'Vasectomy affects urination',
    reality: 'The vas deferens (tubes cut during vasectomy) only carry sperm, not urine. The procedure has no effect on urination.',
  }
];

const Myths = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Myths vs Facts</h1>
        <p className="text-lg text-gray-600">
          Separating common misconceptions from medical facts about vasectomy
        </p>
      </div>

      <div className="mb-12 bg-primary-50 rounded-lg p-6">
        <h2 className="text-2xl font-semibold text-primary-900 mb-4">Important Note</h2>
        <p className="text-primary-800">
          While this information is based on medical research and expert knowledge, it's important to discuss any specific concerns with a healthcare provider. Every individual's situation is unique, and personal medical advice should always be sought for specific cases.
        </p>
      </div>

      <div className="space-y-8">
        {myths.map((item, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="grid md:grid-cols-2">
              {/* Myth Section {/* Myth Section */}
              <div className="p-6 bg-red-50">
                <div className="flex items-start gap-3">
                  <XCircle className="h-6 w-6 text-red-600 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-lg font-medium text-red-900 mb-2">Myth</h3>
                    <p className="text-red-800">{item.myth}</p>
                  </div>
                </div>
              </div>

              {/* Reality Section */}
              <div className="p-6 bg-primary-50">
                <div className="flex items-start gap-3">
                  <CheckCircle className="h-6 w-6 text-primary-600 flex-shrink-0 mt-1" />
                  <div>
                    <h3 className="text-lg font-medium text-primary-900 mb-2">Fact</h3>
                    <p className="text-primary-800">{item.reality}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Myths;