import { Scissors, Syringe, Clock, Ban as Bandage, HeartPulse, AlertTriangle } from 'lucide-react';

const conventionalDetails = [
  {
    title: 'The Procedure',
    icon: <Scissors className="h-6 w-6 text-blue-600" />,
    description: 'Two small incisions (about 1cm each) are made on either side of the scrotum using a scalpel. Through these incisions, the vas deferens are accessed, cut, and sealed. The incisions are then closed with dissolvable stitches.',
    points: [
      'Performed under local anesthesia',
      'Takes approximately 20-30 minutes',
      'Both sides of scrotum are numbed',
      'Requires careful handling of tissue'
    ]
  },
  {
    title: 'Recovery Process',
    icon: <Clock className="h-6 w-6 text-blue-600" />,
    description: 'Recovery from conventional vasectomy typically takes slightly longer due to the nature of the incisions.',
    points: [
      '3-7 days of rest recommended',
      'Stitches dissolve within 7-10 days',
      'Return to light work after 3-4 days',
      'Full recovery within 2-3 weeks'
    ]
  },
  {
    title: 'Post-Procedure Care',
    icon: <Bandage className="h-6 w-6 text-blue-600" />,
    description: 'Proper care of the incision sites is crucial for healing and preventing complications.',
    points: [
      'Keep incision sites clean and dry',
      'Wear supportive underwear',
      'Apply ice packs as directed',
      'Monitor for signs of infection'
    ]
  }
];

const noScalpelDetails = [
  {
    title: 'The Procedure',
    icon: <Syringe className="h-6 w-6 text-blue-600" />,
    description: 'A single small puncture is made in the scrotum using a specialized instrument. The vas deferens are gently lifted through this puncture, cut, and sealed. No stitches are needed as the puncture is so small it heals naturally.',
    points: [
      'Single puncture technique',
      'Takes 15-20 minutes',
      'Minimal tissue trauma',
      'No scalpel required'
    ]
  },
  {
    title: 'Recovery Process',
    icon: <HeartPulse className="h-6 w-6 text-blue-600" />,
    description: 'The no-scalpel method typically offers a faster recovery time due to its minimally invasive nature.',
    points: [
      '2-3 days of rest typically sufficient',
      'No stitches to worry about',
      'Return to work in 2-3 days',
      'Full recovery within 1-2 weeks'
    ]
  },
  {
    title: 'Advantages',
    icon: <AlertTriangle className="h-6 w-6 text-blue-600" />,
    description: 'The no-scalpel method offers several advantages over the conventional approach.',
    points: [
      'Lower risk of complications',
      'Less bleeding and bruising',
      'Faster healing time',
      'Minimal scarring'
    ]
  }
];

const VasectomyTypes = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Types of Vasectomy Procedures</h1>
        <p className="text-lg text-gray-600">
          Understanding the differences between conventional and no-scalpel vasectomy techniques
        </p>
      </div>

      {/* Comparison Overview */}
      <section className="mb-16">
        <div className="bg-blue-50 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Quick Comparison</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-xl font-medium text-blue-800 mb-3">Conventional Vasectomy</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Two incisions required</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Uses traditional surgical tools</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Requires stitches</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Longer recovery time</span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-medium text-blue-800 mb-3">No-Scalpel Vasectomy</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Single small puncture</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Specialized instruments</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>No stitches needed</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                  <span>Faster recovery</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Conventional Vasectomy Details */}
      <section className="mb-16">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">Conventional Vasectomy</h2>
        <div className="space-y-8">
          {conventionalDetails.map((detail, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">{detail.icon}</div>
                <div>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">{detail.title}</h3>
                  <p className="text-gray-600 mb-4">{detail.description}</p>
                  <ul className="space-y-2">
                    {detail.points.map((point, idx) => (
                      <li key={idx} className="flex items-center gap-2">
                        <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                        <span className="text-gray-700">{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* No-Scalpel Vasectomy Details */}
      <section className="mb-16">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">No-Scalpel Vasectomy</h2>
        <div className="space-y-8">
          {noScalpelDetails.map((detail, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">{detail.icon}</div>
                <div>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">{detail.title}</h3>
                  <p className="text-gray-600 mb-4">{detail.description}</p>
                  <ul className="space-y-2">
                    {detail.points.map((point, idx) => (
                      <li key={idx} className="flex items-center gap-2">
                        <span className="h-2 w-2 bg-blue-600 rounded-full"></span>
                        <span className="text-gray-700">{point}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default VasectomyTypes;