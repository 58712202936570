import axios from 'axios';
import { WORDPRESS_API_URL, ENDPOINTS, PER_PAGE, ERROR_MESSAGES, WORDPRESS_ENABLED } from '../config/wordpress';
import type { WordPressPost, WordPressPage, WordPressMedia } from '../types/wordpress';

interface ApiError {
  isError: true;
  message: string;
  status?: number;
  statusText?: string;
  url?: string;
  method?: string;
}

interface HeroCard {
  image?: number;
}

interface InfoCard {
  image?: number;
}

interface WordPressPageWithAcf extends WordPressPage {
  acf: {
    hero_cards?: HeroCard[];
    info_cards?: InfoCard[];
  };
}

function handleError(error: unknown, context: string): ApiError {
  if (process.env.NODE_ENV === 'development') {
    console.error(`Unexpected error (${context}):`, error);
  }
  
  // Type guard for Axios error
  if (error && typeof error === 'object' && 'isAxiosError' in error && error.isAxiosError) {
    const axiosError = error as unknown as { 
      response?: { status: number; statusText: string };
      config?: { url?: string; method?: string };
      message: string;
    };

    if (axiosError.response?.status === 404) {
      return {
        isError: true,
        message: 'Not found',
        status: 404,
        statusText: axiosError.response.statusText,
        url: axiosError.config?.url,
        method: axiosError.config?.method,
      };
    }

    return {
      isError: true,
      message: axiosError.message,
      status: axiosError.response?.status,
      statusText: axiosError.response?.statusText,
      url: axiosError.config?.url,
      method: axiosError.config?.method,
    };
  }

  return {
    isError: true,
    message: error instanceof Error ? error.message : 'Unknown error',
    status: undefined,
    statusText: undefined,
    url: undefined,
    method: undefined,
  };
}

// Test API connectivity
export async function testConnection(): Promise<boolean> {
  if (!WORDPRESS_ENABLED) return false;
  try {
    await axios.get(WORDPRESS_API_URL);
    return true;
  } catch (error) {
    console.error('WordPress API connection test failed:', error);
    return false;
  }
}

// Get all posts
export async function getPosts(page = 1): Promise<WordPressPost[] | ApiError> {
  if (!WORDPRESS_ENABLED) return [];
  try {
    const response = await axios.get<WordPressPost[]>(`${WORDPRESS_API_URL}${ENDPOINTS.POSTS}`, {
      params: {
        page,
        per_page: PER_PAGE,
        _embed: true,
      },
    });
    return response.data;
  } catch (error) {
    return handleError(error, 'Get Posts');
  }
}

// Get single post
export async function getPost(slug: string): Promise<WordPressPost | ApiError> {
  if (!WORDPRESS_ENABLED) throw new Error(ERROR_MESSAGES.API_NOT_ACCESSIBLE);
  try {
    const response = await axios.get<WordPressPost[]>(`${WORDPRESS_API_URL}${ENDPOINTS.POSTS}`, {
      params: {
        slug,
        _embed: true,
      },
    });
    return response.data[0];
  } catch (error) {
    return handleError(error, 'Get Single Post');
  }
}

// Get page by slug
export async function getPage(slug: string): Promise<WordPressPageWithAcf | ApiError> {
  if (!WORDPRESS_ENABLED) throw new Error(ERROR_MESSAGES.API_NOT_ACCESSIBLE);
  try {
    const response = await axios.get<WordPressPageWithAcf[]>(`${WORDPRESS_API_URL}${ENDPOINTS.PAGES}`, {
      params: {
        slug,
      },
    });
    return response.data[0];
  } catch (error) {
    return handleError(error, 'Page');
  }
}

// Get media by ID
export async function getMedia(id: number): Promise<WordPressMedia | ApiError> {
  if (!WORDPRESS_ENABLED) throw new Error(ERROR_MESSAGES.API_NOT_ACCESSIBLE);
  try {
    const response = await axios.get<WordPressMedia>(`${WORDPRESS_API_URL}${ENDPOINTS.MEDIA}/${id}`);
    return response.data;
  } catch (error) {
    return handleError(error, 'Media');
  }
}

// Get all media for hero and info cards
export async function getCardsMedia(page: WordPressPageWithAcf): Promise<(WordPressMedia | ApiError)[]> {
  if (!WORDPRESS_ENABLED) return [];
  if ('isError' in page) return [];

  const { hero_cards, info_cards } = page.acf;

  const mediaPromises = [
    ...(hero_cards?.map(card => card.image ? getMedia(card.image) : null) || []),
    ...(info_cards?.map(card => card.image ? getMedia(card.image) : null) || [])
  ].filter((promise): promise is Promise<WordPressMedia | ApiError> => promise !== null);

  return Promise.all(mediaPromises);
}