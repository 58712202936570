// WordPress API configuration
const DEFAULT_API_URL = import.meta.env.PROD 
  ? 'https://vasectomy.com.au/wp-json/wp/v2'  // Production WordPress API
  : 'http://localhost:8000/wp-json/wp/v2';     // Local WordPress API

export const WORDPRESS_API_URL = import.meta.env.VITE_WORDPRESS_API_URL || DEFAULT_API_URL;

// Add a flag to check if we're in preview mode
export const IS_PREVIEW = import.meta.env.MODE === 'preview' || window.location.hostname.includes('netlify');

// If we're in preview mode and no API URL is set, disable WordPress features
export const WORDPRESS_ENABLED = !IS_PREVIEW || Boolean(import.meta.env.VITE_WORDPRESS_API_URL);

export const ENDPOINTS = {
  POSTS: '/posts',
  PAGES: '/pages',
  MEDIA: '/media',
  CATEGORIES: '/categories',
  TAGS: '/tags',
  USERS: '/users',
  SEARCH: '/search',
} as const;

export const PER_PAGE = 10;

export const ERROR_MESSAGES = {
  API_NOT_ACCESSIBLE: 'WordPress API is not accessible',
  POSTS_FETCH_ERROR: 'Failed to fetch posts',
  POST_FETCH_ERROR: 'Failed to fetch post',
  PAGE_FETCH_ERROR: 'Failed to fetch page',
  MEDIA_FETCH_ERROR: 'Failed to fetch media',
  CATEGORIES_FETCH_ERROR: 'Failed to fetch categories',
  TAGS_FETCH_ERROR: 'Failed to fetch tags',
  USERS_FETCH_ERROR: 'Failed to fetch users',
  SEARCH_ERROR: 'Failed to perform search',
} as const;