import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, User, Clock } from 'lucide-react';
import { usePost } from '../hooks/useWordPress';

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const { data: post, isLoading, error } = usePost(slug || '');

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="animate-pulse">
          <div className="h-8 bg-gray-200 rounded w-1/4 mb-8"></div>
          <div className="space-y-4">
            <div className="h-4 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex items-center text-red-700">
            <p>Error loading blog post. Please try again later.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Link to="/blog" className="inline-flex items-center text-gray-600 hover:text-gray-900 mb-8">
        <ArrowLeft className="h-4 w-4 mr-2" />
        Back to Blog
      </Link>
      <article className="prose max-w-none">
        <h1 className="text-3xl font-bold mb-4">{post.title.rendered}</h1>
        <div className="flex items-center text-sm text-gray-500 mb-8">
          <User className="h-4 w-4 mr-1" />
          <span className="mr-4">Admin</span>
          <Clock className="h-4 w-4 mr-1" />
          <span>{new Date(post.date).toLocaleDateString()}</span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
      </article>
    </div>
  );
}