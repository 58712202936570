import { useState } from 'react';
import { Search } from 'lucide-react';

const faqs = [
  {
    category: 'General',
    questions: [
      {
        question: 'What is a vasectomy?',
        answer: 'A vasectomy is a permanent form of male birth control that involves cutting or blocking the vas deferens, the tubes that carry sperm from the testicles. This prevents sperm from mixing with semen during ejaculation.'
      },
      {
        question: 'Is a vasectomy permanent?',
        answer: 'Yes, a vasectomy should be considered permanent. While reversal procedures exist, they are complex, expensive, and success rates vary. It\'s important to be certain about your decision before proceeding.'
      },
      {
        question: 'Will it affect my sex drive or performance?',
        answer: 'No, a vasectomy does not affect testosterone levels, sex drive, erections, or sexual performance. The only change is that your semen will no longer contain sperm.'
      },
      {
        question: 'How effective is a vasectomy?',
        answer: 'Vasectomy is one of the most effective forms of birth control, with a success rate of over 99%. However, it\'s important to complete post-vasectomy semen analysis to confirm success.'
      },
      {
        question: 'What age is appropriate for a vasectomy?',
        answer: 'There is no legal minimum age for vasectomy in Australia, but you must be able to make an informed decision. Most doctors recommend being sure about not wanting future children before proceeding.'
      }
    ]
  },
  {
    category: 'Procedure',
    questions: [
      {
        question: 'How long does the procedure take?',
        answer: 'A typical vasectomy procedure takes about 20-30 minutes and is performed under local anesthesia in a doctor\'s office or clinic.'
      },
      {
        question: 'Is the procedure painful?',
        answer: 'During the procedure, you\'ll feel little to no pain due to local anesthesia. Some mild discomfort, swelling, and bruising may occur for a few days afterward.'
      },
      {
        question: 'When can I return to work?',
        answer: 'Most men can return to work within 2-3 days, though you should avoid heavy physical labor for about a week.'
      },
      {
        question: 'What type of anesthesia is used?',
        answer: 'Local anesthesia is used to numb the scrotum area. You\'ll be awake during the procedure but won\'t feel pain. Some clinics offer additional anti-anxiety medication if requested.'
      },
      {
        question: 'Do I need to shave before the procedure?',
        answer: 'Your doctor will provide specific pre-procedure instructions. Many clinics prefer to do any necessary shaving themselves to reduce infection risk and ensure proper preparation.'
      }
    ]
  },
  {
    category: 'After Surgery',
    questions: [
      {
        question: 'When is the vasectomy effective?',
        answer: 'A vasectomy is not immediately effective. You\'ll need to use alternative birth control until a semen analysis confirms there are no sperm present, typically after 20-30 ejaculations or 3 months.'
      },
      {
        question: 'Are there any long-term health risks?',
        answer: 'No, studies have not shown any significant long-term health risks associated with vasectomy. It does not increase your risk of cancer, heart disease, or other health problems.'
      },
      {
        question: 'Will it protect against STDs?',
        answer: 'No, a vasectomy does not protect against sexually transmitted diseases (STDs). You should still practice safe sex if STD prevention is a concern.'
      },
      {
        question: 'When can I resume sexual activity?',
        answer: 'Most men can resume sexual activity after about a week, or when comfortable. Remember to use alternative contraception until your doctor confirms the vasectomy is effective.'
      },
      {
        question: 'What follow-up care is required?',
        answer: 'You\'ll need at least one semen analysis, usually 3 months after the procedure, to confirm success. Annual check-ups are recommended to monitor your general health.'
      }
    ]
  },
  {
    category: 'Cost & Insurance',
    questions: [
      {
        question: 'How much does a vasectomy cost?',
        answer: 'Costs vary depending on location and provider. In Australia, vasectomies are covered by Medicare, with out-of-pocket expenses typically ranging from $100 to $500 after rebates.'
      },
      {
        question: 'Is it covered by Medicare?',
        answer: 'Yes, vasectomy is covered by Medicare in Australia. You\'ll receive a rebate for part of the cost. Private health insurance may cover additional expenses.'
      },
      {
        question: 'Are there payment plans available?',
        answer: 'Many clinics offer payment plans or flexible payment options. It\'s best to discuss financial arrangements with your chosen provider before the procedure.'
      }
    ]
  },
  {
    category: 'Complications',
    questions: [
      {
        question: 'What are the possible complications?',
        answer: 'While rare, possible complications include infection, bleeding, chronic pain (1-2% of cases), and sperm granuloma. Most complications are minor and can be treated effectively.'
      },
      {
        question: 'What is post-vasectomy pain syndrome?',
        answer: 'Post-vasectomy pain syndrome (PVPS) is chronic scrotal pain that occurs in a small percentage of men (1-2%). It\'s important to discuss this rare but potential risk with your doctor.'
      },
      {
        question: 'How successful are vasectomy reversals?',
        answer: 'Success rates for reversals vary from 40-90%, depending on factors like time since vasectomy and surgeon experience. Reversals are complex, expensive, and not guaranteed to restore fertility.'
      }
    ]
  }
];

const FAQs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const filteredFAQs = faqs.map(category => ({
    ...category,
    questions: category.questions.filter(
      q => 
        (selectedCategory === 'all' || category.category === selectedCategory) &&
        (q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.answer.toLowerCase().includes(searchTerm.toLowerCase()))
    )
  })).filter(category => category.questions.length > 0);

  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative h-[300px] mb-12 rounded-lg overflow-hidden">
        <img 
          src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d?auto=format&fit=crop&q=80"
          alt="Medical consultation"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40" />
        <div className="relative h-full flex flex-col items-center justify-center px-4">
          <h1 className="text-4xl font-bold text-white mb-4 text-shadow">Frequently Asked Questions</h1>
          <p className="text-lg text-gray-200 text-shadow">
            Find answers to common questions about vasectomy procedures and aftercare
          </p>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="mb-8 space-y-4">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
          <input
            type="text"
            placeholder="Search FAQs..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex flex-wrap gap-2">
          <button
            onClick={() => setSelectedCategory('all')}
            className={`px-4 py-2 rounded-lg ${
              selectedCategory === 'all'
                ? 'bg-primary-500 text-white'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }`}
          >
            All
          </button>
          {faqs.map(category => (
            <button
              key={category.category}
              onClick={() => setSelectedCategory(category.category)}
              className={`px-4 py-2 rounded-lg ${
                selectedCategory === category.category
                  ? 'bg-primary-500 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              {category.category}
            </button>
          ))}
        </div>
      </div>

      {/* FAQ List */}
      <div className="space-y-8">
        {filteredFAQs.map(category => (
          <div key={category.category}>
            <h2 className="text-2xl font-semibold text-gray-900 mb-4">{category.category}</h2>
            <div className="space-y-4">
              {category.questions.map((faq, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow"
                >
                  <h3 className="text-lg font-medium text-gray-900 mb-2">{faq.question}</h3>
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;