import { CheckCircle, XCircle, AlertTriangle } from 'lucide-react';

const benefits = [
  {
    title: 'Highly Effective',
    description: 'Nearly 100% effective at preventing pregnancy after confirmed sterility.',
  },
  {
    title: 'Permanent Solution',
    description: 'One-time procedure for permanent birth control.',
  },
  {
    title: 'No Hormonal Side Effects',
    description: 'Does not affect testosterone levels or male characteristics.',
  },
  {
    title: 'Cost-Effective',
    description: 'One-time cost compared to ongoing contraception expenses.',
  },
  {
    title: 'Quick Recovery',
    description: 'Most men return to work within a few days.',
  },
  {
    title: 'No Change in Sexual Function',
    description: 'Maintains normal erection, orgasm, and ejaculation.',
  }
];

const risks = [
  {
    title: 'Post-Procedure Pain',
    description: 'Temporary discomfort and swelling for a few days.',
    severity: 'mild'
  },
  {
    title: 'Infection',
    description: 'Rare but possible complication requiring antibiotics.',
    severity: 'moderate'
  },
  {
    title: 'Sperm Granuloma',
    description: 'Small lumps that may form due to sperm leakage, usually harmless.',
    severity: 'mild'
  },
  {
    title: 'Chronic Pain',
    description: 'Very rare cases of long-term discomfort (1-2% of cases).',
    severity: 'severe'
  }
];

const considerations = [
  'Permanent nature of the procedure',
  'Current and future family planning',
  'Partner input and alternative options',
  'Recovery time and work schedule',
  'Insurance coverage and costs',
  'Finding an experienced surgeon'
];

const BenefitsRisks = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">Benefits and Risks</h1>
        <p className="text-lg text-gray-600">
          Understanding the advantages and potential complications of vasectomy
        </p>
      </div>

      {/* Important Considerations */}
      <section className="mb-16">
        <div className="flex items-center gap-2 mb-6">
          <AlertTriangle className="h-6 w-6 text-primary-500" />
          <h2 className="text-2xl font-semibold text-gray-900">Important Considerations</h2>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <ul className="space-y-4">
            {considerations.map((consideration, index) => (
              <li key={index} className="flex items-center gap-3">
                <div className="h-2 w-2 bg-primary-500 rounded-full"></div>
                <span className="text-gray-700">{consideration}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="mb-16">
        <div className="flex items-center gap-2 mb-6">
          <CheckCircle className="h-6 w-6 text-primary-500" />
          <h2 className="text-2xl font-semibold text-gray-900">Benefits</h2>
        </div>
        <div className="grid md:grid-cols-2 gap-6">
          {benefits.map((benefit, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">{benefit.title}</h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Risks Section */}
      <section className="mb-16">
        <div className="flex items-center gap-2 mb-6">
          <XCircle className="h-6 w-6 text-red-600" />
          <h2 className="text-2xl font-semibold text-gray-900">Potential Risks</h2>
        </div>
        <div className="space-y-6">
          {risks.map((risk, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="text-lg font-medium text-gray-900 mb-2">{risk.title}</h3>
                  <p className="text-gray-600">{risk.description}</p>
                </div>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                  risk.severity === 'mild' ? 'bg-primary-100 text-primary-800' :
                  risk.severity === 'moderate' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'
                }`}>
                  {risk.severity.charAt(0).toUpperCase() + risk.severity.slice(1)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default BenefitsRisks;