import React from 'react';
import { motion, useSpring, useTransform } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

interface CountUpProps {
  end: number;
  suffix?: string;
  duration?: number;
  className?: string;
}

const CountUp: React.FC<CountUpProps> = ({ end, suffix = '', duration = 2, className = '' }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const count = useSpring(0, {
    stiffness: 50,
    damping: 20,
    duration: duration * 1000,
  });

  const rounded = useTransform(count, (value) => Math.round(value));

  React.useEffect(() => {
    if (inView) {
      count.set(end);
    }
  }, [count, end, inView]);

  return (
    <div ref={ref} className={className}>
      <motion.span>{rounded}</motion.span>
      {suffix}
    </div>
  );
};

export default CountUp;