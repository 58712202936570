import { Clock, Activity, AlertTriangle, Heart } from 'lucide-react';

const timelineSteps = [
  {
    title: 'First 24-48 Hours',
    icon: <Clock className="h-6 w-6 text-primary-500" />,
    description: 'The most critical period for proper healing.',
    instructions: [
      'Rest completely, avoid any strenuous activity',
      'Apply ice packs for 20 minutes every 2-3 hours',
      'Wear supportive underwear continuously',
      'Keep the surgical site clean and dry',
      'Take prescribed pain medication as needed'
    ]
  },
  {
    title: 'Days 3-7',
    icon: <Activity className="h-6 w-6 text-primary-500" />,
    description: 'Gradual return to light activities.',
    instructions: [
      'Return to light desk work (if applicable)',
      'Continue wearing supportive underwear',
      'Avoid heavy lifting (nothing over 10kg)',
      'Short walks are encouraged',
      'Continue keeping the area clean and dry'
    ]
  },
  {
    title: 'Weeks 1-2',
    icon: <AlertTriangle className="h-6 w-6 text-primary-500" />,
    description: 'Increasing activity with caution.',
    instructions: [
      'Resume most daily activities',
      'Avoid vigorous exercise',
      'No heavy lifting or sports',
      'Sexual activity may resume if comfortable',
      'Watch for any signs of complications'
    ]
  },
  {
    title: 'After Week 2',
    icon: <Heart className="h-6 w-6 text-primary-500" />,
    description: 'Return to normal activities.',
    instructions: [
      'Resume all normal activities as comfortable',
      'Begin exercise gradually',
      'Continue using contraception until cleared',
      'Attend follow-up appointments as scheduled',
      'Complete post-vasectomy semen analysis when required'
    ]
  }
];

const warningSignsList = [
  'Severe or increasing pain',
  'Excessive swelling or bruising',
  'Fever or chills',
  'Pus or discharge from the incision',
  'Bleeding that will not stop',
  'Redness or warmth around the surgical site'
];

const Recovery = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative mb-12">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1517836357463-d25dfeac3438?auto=format&fit=crop&q=80"
            alt="Man enjoying life"
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40 rounded-lg" />
        </div>
        <div className="relative text-center py-16">
          <h1 className="text-4xl font-bold text-white mb-4 text-shadow">Recovery Guide</h1>
          <p className="text-lg text-gray-200 text-shadow">
            Understanding what to expect during your recovery period and how to ensure the best healing process
          </p>
        </div>
      </div>

      {/* Warning Signs */}
      <section className="mb-16">
        <div className="bg-red-50 rounded-lg p-6">
          <h2 className="text-2xl font-semibold text-red-900 mb-4 flex items-center gap-2">
            <AlertTriangle className="h-6 w-6" />
            Warning Signs
          </h2>
          <p className="text-red-800 mb-4">
            Contact your healthcare provider immediately if you experience any of the following:
          </p>
          <ul className="grid md:grid-cols-2 gap-4">
            {warningSignsList.map((sign, index) => (
              <li key={index} className="flex items-center gap-2 text-red-800">
                <span className="h-2 w-2 bg-red-600 rounded-full"></span>
                <span>{sign}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {/* Tips for Optimal Recovery */}
      <section className="mb-16">
        <div className="relative mb-8">
          <img 
            src="https://images.unsplash.com/photo-1551022372-0bdac482b9d6?auto=format&fit=crop&q=80"
            alt="Man relaxing at home"
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40 rounded-lg" />
          <h2 className="absolute inset-0 flex items-center justify-center text-2xl font-semibold text-white text-shadow">
            Tips for Optimal Recovery
          </h2>
        </div>
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-4">Do's</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-primary-500 rounded-full"></span>
                  <span className="text-gray-700">Follow all post-operative instructions</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-primary-500 rounded-full"></span>
                  <span className="text-gray-700">Keep the surgical area clean</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-primary-500 rounded-full"></span>
                  <span className="text-gray-700">Wear supportive underwear</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-primary-500 rounded-full"></span>
                  <span className="text-gray-700">Take prescribed medications as directed</span>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-4">Don'ts</h3>
              <ul className="space-y-2">
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-red-600 rounded-full"></span>
                  <span className="text-gray-700">Don't lift heavy objects</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-red-600 rounded-full"></span>
                  <span className="text-gray-700">Don't exercise too soon</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-red-600 rounded-full"></span>
                  <span className="text-gray-700">Don't ignore warning signs</span>
                </li>
                <li className="flex items-center gap-2">
                  <span className="h-2 w-2 bg-red-600 rounded-full"></span>
                  <span className="text-gray-700">Don't skip follow-up appointments</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Timeline */}
      <section className="mb-16">
        <div className="relative mb-8">
          <img 
            src="https://images.unsplash.com/photo-1516307073-35c95c3c0753?auto=format&fit=crop&q=80"
            alt="Man enjoying outdoor activities"
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40 rounded-lg" />
          <h2 className="absolute inset-0 flex items-center justify-center text-2xl font-semibold text-white text-shadow">
            Recovery Timeline
          </h2>
        </div>
        <div className="space-y-8">
          {timelineSteps.map((step, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">{step.icon}</div>
                <div>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">{step.title}</h3>
                  <p className="text-gray-600 mb-4">{step.description}</p>
                  <ul className="space-y-2">
                    {step.instructions.map((instruction, idx) => (
                      <li key={idx} className="flex items-center gap-2">
                        <span className="h-2 w-2 bg-primary-500 rounded-full"></span>
                        <span className="text-gray-700">{instruction}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Recovery;