import { Link } from 'react-router-dom';
import { Info, Book, Clock, ChevronRight, Stethoscope, HeartPulse, HelpCircle, DollarSign } from 'lucide-react';
import CountUp from '../components/CountUp';
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardTitle } from "@/components/ui/card";
import { motion } from "framer-motion";

const features = [
  {
    icon: <Book className="h-6 w-6 text-primary-500" />,
    title: 'Educational Resources',
    description: 'Learn about the procedure, recovery, and what to expect.',
    link: '/procedure'
  },
  {
    icon: <Clock className="h-6 w-6 text-primary-500" />,
    title: 'Recovery Guidelines',
    description: 'Detailed recovery timeline and post-procedure care instructions.',
    link: '/recovery'
  },
  {
    icon: <Info className="h-6 w-6 text-primary-500" />,
    title: 'Myths & Facts',
    description: 'Get accurate information and dispel common misconceptions about vasectomy.',
    link: '/myths'
  }
];

const statistics = [
  {
    number: 30,
    suffix: '+',
    label: 'Minutes Average Procedure Time',
    animate: true
  },
  {
    number: 99,
    suffix: '%',
    label: 'Effectiveness Rate',
    animate: true
  },
  {
    display: '2 - 3',
    label: 'Days Average Recovery',
    animate: false
  },
  {
    number: 85000,
    suffix: '+',
    label: 'Annual Procedures in Australia',
    animate: true
  }
];

const infoCards = [
  {
    title: 'Medicare Covered',
    description: 'Vasectomy procedures are covered under Medicare in Australia, making it an accessible choice for permanent contraception.',
    image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?auto=format&fit=crop&q=80',
    icon: <DollarSign className="h-6 w-6 text-primary-500" />,
    link: '/faqs',
    linkText: 'Learn About Costs'
  },
  {
    title: 'Expert Care',
    description: 'Access to experienced Australian healthcare providers who perform thousands of successful procedures each year.',
    image: 'https://images.unsplash.com/photo-1666214280557-f1b5022eb634?auto=format&fit=crop&q=80',
    icon: <Stethoscope className="h-6 w-6 text-primary-500" />,
    link: '/procedure',
    linkText: 'Meet Our Specialists'
  },
  {
    title: 'Types of Vasectomy',
    description: 'Compare traditional and no-scalpel vasectomy procedures to understand which option might be best for you.',
    image: 'https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&q=80',
    icon: <HeartPulse className="h-6 w-6 text-primary-500" />,
    link: '/vasectomy-types',
    linkText: 'Compare Procedures'
  },
  {
    title: 'Support & Resources',
    description: 'Access comprehensive resources, FAQs, and support throughout your vasectomy journey.',
    image: 'https://images.unsplash.com/photo-1581056771107-24ca5f033842?auto=format&fit=crop&q=80',
    icon: <HelpCircle className="h-6 w-6 text-primary-500" />,
    link: '/blog',
    linkText: 'View Resources'
  }
];

const heroCards = [
  {
    title: 'Expert Care',
    description: 'Access to experienced healthcare providers across Australia',
    link: '/procedure',
    variant: 'secondary' as const,
    className: 'bg-white/10 backdrop-blur-sm border border-white/20 hover:bg-primary-500/50',
    delay: 1
  },
  {
    title: 'Medicare Covered',
    description: 'Procedures covered under Medicare in Australia',
    link: '/faqs',
    variant: 'secondary' as const,
    className: 'bg-primary-500/10 backdrop-blur-sm border border-primary-500/20 hover:bg-primary-500/50',
    delay: 2
  },
  {
    title: 'Quick Recovery',
    description: 'Most men return to work within 2-3 days',
    link: '/recovery',
    variant: 'secondary' as const,
    className: 'bg-white/10 backdrop-blur-sm border border-white/20 hover:bg-primary-500/50',
    delay: 3
  },
  {
    title: 'High Success Rate',
    description: '99% effectiveness rate for birth control',
    link: '/procedure#success-rate',
    variant: 'secondary' as const,
    className: 'bg-primary-500/10 backdrop-blur-sm border border-primary-500/20 hover:bg-primary-500/50',
    delay: 4
  }
];

const Home = () => {
  return (
    <div className="space-y-8">
      {/* Hero Section */}
      <section className="relative min-h-[70vh] flex items-center">
        <div className="absolute inset-0 w-full h-full rounded-2xl overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900/95 to-gray-900/50 backdrop-blur-[2px]" />
          <img 
            src="https://images.unsplash.com/photo-1579684385127-1ef15d508118?auto=format&fit=crop&q=80"
            alt="Medical professional in consultation"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <div>
                <motion.div 
                  className="inline-flex items-center px-4 py-2 rounded-full bg-primary-500/10 backdrop-blur-sm border border-primary-500/20"
                  animate={{ 
                    scale: [1, 1.05, 1],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                >
                  <span className="text-primary-300 font-medium">Australia's Trusted Vasectomy Resource</span>
                </motion.div>
              </div>
              <h1 className="text-5xl md:text-6xl lg:text-7xl font-bold text-white leading-tight">
                Making Informed Decisions About <span className="text-primary-400">Vasectomy</span>
              </h1>
              <p className="text-xl text-gray-300 max-w-xl">
                Comprehensive, accurate information to help you understand vasectomy procedures,
                recovery, and aftercare in Australia.
              </p>
              <div className="flex flex-wrap gap-4">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 1,
                    ease: "easeOut"
                  }}
                  whileHover={{ 
                    scale: 1.05,
                    transition: { duration: 0.2 }
                  }}
                >
                  <Button 
                    asChild 
                    size="lg" 
                    className="bg-primary-500 hover:bg-primary-500/90 text-white shadow-lg"
                  >
                    <Link to="/procedure">
                      Learn About the Procedure
                    </Link>
                  </Button>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 2,
                    ease: "easeOut"
                  }}
                  whileHover={{ 
                    scale: 1.05,
                    transition: { duration: 0.2 }
                  }}
                >
                  <Button 
                    asChild 
                    variant="secondary"
                    size="lg" 
                    className="bg-white/10 hover:bg-primary-500/50 backdrop-blur-sm border border-white/20 text-white"
                  >
                    <Link to="/faqs">
                      View FAQs
                    </Link>
                  </Button>
                </motion.div>
              </div>
            </div>
            <div className="hidden lg:block relative">
              <div className="grid grid-cols-2 gap-4">
                {heroCards.map((card, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ 
                      opacity: 1, 
                      y: 0,
                      scale: [1, 1.03, 1]
                    }}
                    transition={{ 
                      opacity: {
                        duration: 0.5,
                        delay: card.delay,
                        ease: "easeOut"
                      },
                      y: {
                        duration: 0.5,
                        delay: card.delay,
                        ease: "easeOut"
                      },
                      scale: {
                        duration: 1.5,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: 0
                      }
                    }}
                    className={index >= 2 ? "mt-4" : ""}
                  >
                    <Button
                      asChild
                      variant={card.variant}
                      className={`w-full h-full p-6 flex flex-col items-start justify-start rounded-xl ${card.className}`}
                    >
                      <Link to={card.link} className="w-full">
                        <h3 className="text-white font-semibold mb-2 break-words">{card.title}</h3>
                        <p className="text-gray-300 text-sm text-left break-words whitespace-normal">{card.description}</p>
                      </Link>
                    </Button>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Statistics Section */}
      <section className="relative min-h-[15vh] flex items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {statistics.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: (index + 1) * 0.2,
                    ease: "easeOut"
                  }}
                  whileHover={{ 
                    scale: 1.02,
                    transition: { duration: 0.2 }
                  }}
                >
                  <div className="bg-white rounded-3xl shadow-lg p-8 text-center h-full">
                    <div className="text-4xl font-bold text-primary-500 mb-2">
                      {stat.animate ? (
                        <CountUp
                          end={stat.number!}
                          suffix={stat.suffix}
                          className="text-4xl font-bold text-primary-500"
                        />
                      ) : (
                        stat.display
                      )}
                    </div>
                    <div className="text-sm text-gray-500">{stat.label}</div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="relative min-h-[40vh] flex items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="relative bg-gradient-to-b from-[#B1CC70] from-0% via-[#B1CC70] via-77% to-gray-50 rounded-3xl shadow-lg p-12">
              <div className="relative text-center max-w-3xl mx-auto">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                  Why Trust vasectomy.com.au?
                </h2>
                <p className="mt-4 text-lg font-semibold text-white">
                  Your trusted source for comprehensive vasectomy information in Australia
                </p>
              </div>
              <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ 
                      duration: 0.5,
                      delay: (index + 1),
                      ease: "easeOut"
                    }}
                    whileHover={{ 
                      scale: 1.02,
                      transition: { duration: 0.2 }
                    }}
                    className="h-full"
                  >
                    <Link
                      to={feature.link}
                      className="relative block p-8 bg-white/90 backdrop-blur-sm rounded-2xl shadow-md hover:shadow-lg transition-all group h-full flex flex-col min-h-[200px]"
                    >
                      <div className="mb-4 flex-shrink-0">
                        {feature.icon}
                      </div>
                      <h3 className="text-xl font-semibold text-gray-900 group-hover:text-primary-500 transition-colors flex-shrink-0">{feature.title}</h3>
                      <p className="mt-2 text-gray-500 flex-grow">{feature.description}</p>
                    </Link>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Information Sections */}
      <section className="relative min-h-[40vh] flex items-center">
        <div className="w-full px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="grid md:grid-cols-2 gap-8">
              {infoCards.map((card, index) => (
                <div key={index}>
                  <Card className="relative overflow-hidden border-0 shadow-lg h-[400px]">
                    <div className="absolute inset-0">
                      <img 
                        src={card.image}
                        alt={card.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-primary-900/90 via-primary-900/50 to-transparent backdrop-blur-[1px]" />
                    </div>
                    <CardContent className="relative h-full flex flex-col justify-end p-6">
                      <div className="flex items-center gap-2 mb-2">
                        {card.icon}
                        <CardTitle className="text-2xl font-bold text-white">{card.title}</CardTitle>
                      </div>
                      <CardDescription className="text-white text-base mb-4">
                        {card.description}
                      </CardDescription>
                      <CardFooter className="p-0">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ 
                            duration: 0.5,
                            delay: (index + 1) * 0.2,
                            ease: "easeOut"
                          }}
                          className="w-full"
                        >
                          <Button
                            asChild
                            size="lg"
                            className="bg-primary-500 hover:bg-primary-500/90 text-white shadow-lg w-[200px] h-[48px]"
                          >
                            <Link to={card.link} className="flex items-center justify-center">
                              {card.linkText}
                              <ChevronRight className="ml-2 h-4 w-4" />
                            </Link>
                          </Button>
                        </motion.div>
                      </CardFooter>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;