import { useQuery } from '@tanstack/react-query';
import { getPosts, getPost, getPage } from '../services/wordpress';
import type { WordPressPost, WordPressPage } from '../types/wordpress';

// Custom hook for fetching posts
export function usePosts(page = 1) {
  return useQuery<WordPressPost[], Error>({
    queryKey: ['posts', page],
    queryFn: async () => {
      const result = await getPosts(page);
      if ('isError' in result) {
        throw new Error(result.message);
      }
      return result;
    },
  });
}

// Custom hook for fetching a single post
export function usePost(slug: string) {
  return useQuery<WordPressPost, Error>({
    queryKey: ['post', slug],
    queryFn: async () => {
      const result = await getPost(slug);
      if ('isError' in result) {
        throw new Error(result.message);
      }
      return result;
    },
  });
}

// Custom hook for fetching a page
export function usePage(slug: string) {
  return useQuery<WordPressPage, Error>({
    queryKey: ['page', slug],
    queryFn: async () => {
      const result = await getPage(slug);
      if ('isError' in result) {
        throw new Error(result.message);
      }
      return result;
    },
  });
}