import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout';
import Home from './pages/Home';
import FAQs from './pages/FAQs';
import Procedure from './pages/Procedure';
import BenefitsRisks from './pages/BenefitsRisks';
import VasectomyTypes from './pages/VasectomyTypes';
import Recovery from './pages/Recovery';
import Myths from './pages/Myths';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/procedure" element={<Procedure />} />
          <Route path="/benefits-risks" element={<BenefitsRisks />} />
          <Route path="/vasectomy-types" element={<VasectomyTypes />} />
          <Route path="/recovery" element={<Recovery />} />
          <Route path="/myths" element={<Myths />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;