import { Clock, CheckCircle, AlertCircle, Stethoscope } from 'lucide-react';
import { Link } from 'react-router-dom';

const procedureSteps = [
  {
    title: 'Initial Consultation',
    description: 'Meet with a specialist to discuss the procedure, your medical history, and confirm your decision.',
    icon: <Stethoscope className="h-6 w-6 text-primary-500" />,
    duration: '30-60 minutes'
  },
  {
    title: 'Preparation',
    description: 'The area is cleaned and local anesthesia is administered. You will remain awake but will not feel pain.',
    icon: <CheckCircle className="h-6 w-6 text-primary-500" />,
    duration: '10-15 minutes'
  },
  {
    title: 'The Procedure',
    description: 'Small incisions are made to access and cut or block the vas deferens tubes. The incisions are then closed.',
    icon: <Clock className="h-6 w-6 text-primary-500" />,
    duration: '20-30 minutes'
  },
  {
    title: 'Recovery',
    description: 'Brief rest period before heading home. You will need someone to drive you.',
    icon: <AlertCircle className="h-6 w-6 text-primary-500" />,
    duration: '30 minutes'
  }
];

const Procedure = () => {
  return (
    <div className="max-w-4xl mx-auto">
      <div className="relative mb-12">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1666214280557-f1b5022eb634?auto=format&fit=crop&q=80"
            alt="Medical consultation room"
            className="w-full h-64 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40 rounded-lg" />
        </div>
        <div className="relative text-center py-16">
          <h1 className="text-4xl font-bold text-white mb-4 text-shadow">Vasectomy Procedure Overview</h1>
          <p className="text-lg text-gray-200 mb-6 text-shadow">
            Understanding what happens before, during, and after your vasectomy procedure
          </p>
          <Link 
            to="/vasectomy-types" 
            className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-primary-600 bg-white hover:bg-gray-100"
          >
            Learn About Different Types of Vasectomy
          </Link>
        </div>
      </div>

      {/* What to Expect */}
      <section className="mb-16">
        <h2 className="text-2xl font-semibold text-gray-900 mb-6">What to Expect</h2>
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-4">Before the Procedure</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Avoid blood-thinning medications</li>
                <li>• Shower and clean the area</li>
                <li>• Wear comfortable clothing</li>
                <li>• Arrange for transportation home</li>
                <li>• Have ice packs ready at home</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-medium text-gray-900 mb-4">After the Procedure</h3>
              <ul className="space-y-2 text-gray-600">
                <li>• Rest for 24-48 hours</li>
                <li>• Apply ice packs as directed</li>
                <li>• Wear supportive underwear</li>
                <li>• Avoid heavy lifting for a week</li>
                <li>• Follow up for semen analysis</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Step by Step Process */}
      <section className="mb-16">
        <div className="relative mb-8">
          <img 
            src="https://images.unsplash.com/photo-1584515933487-779824d29309?auto=format&fit=crop&q=80"
            alt="Medical procedure preparation"
            className="w-full h-48 object-cover rounded-lg"
          />
          <div className="absolute inset-0 bg-gradient-to-r from-primary-500/40 to-primary-700/40 rounded-lg" />
          <h2 className="absolute inset-0 flex items-center justify-center text-2xl font-semibold text-white text-shadow">
            The Process
          </h2>
        </div>
        <div className="space-y-8">
          {procedureSteps.map((step, index) => (
            <div key={index} className="bg-white rounded-lg shadow-md p-6">
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0">{step.icon}</div>
                <div>
                  <h3 className="text-xl font-medium text-gray-900 mb-2">{step.title}</h3>
                  <p className="text-gray-600 mb-2">{step.description}</p>
                  <p className="text-sm text-primary-600">Duration: {step.duration}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Procedure;